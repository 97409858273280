































































































































































































































































































































































































































































































































































































@import '../styles/bootstrap_theme';

.suggestion-input-group {
  position: relative;
}
.suggestion-input {
  position: absolute;
  top: 0;
}
.suggestion-list-group {
  position: relative;
  width: 100%;
}
.suggestion-list {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
}

@media screen and (min-width:768px) {
  #datasetFacets {
    display:block
  }
}
