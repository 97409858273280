








































































































































































































































@import '../styles/bootstrap_theme';

.data-info-box {
  border-color: #ced4da !important;

  &:hover {
    border-color: #5c636a !important;
    cursor: pointer;
  }

  .formats.badge.rounded-pill {
    max-width: 6rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
