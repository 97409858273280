














































































































































































































































































































































































































































































































































































































































































































@import '../styles/bootstrap_theme';

.heading, .arrow, .copy-text {
  cursor: pointer;
}

.distribution-pill, .keyword-pill {
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/*** BOOTSTRAP ***/
button:focus {
  outline:0;
}
.options, .download {
  .dropdown-menu {
    min-width: 300px;
    .dropdown-item {
      &:hover {
        color: initial;
        background-color: initial;
      }
    }
  }
}
