


















































































































































































































































































































































































































































































































































@import '../styles/bootstrap_theme';

.facet:hover {
  cursor: pointer;
}

.map {
  z-index: 0;
}
.suggestion-input-group {
  position: relative;
}
.suggestion-input {
  position: absolute;
  top: 0;
  height: 100%;
}
.suggestion-list-group {
  position: relative;
  width: 100%;
}
.suggestion-list {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
}

.map-modal-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

#modal-map-wrapper .modal-dialog {
  max-width: 100%;
}

.modal-content {
  min-height: 100%;
  min-height: 100vh;
}

#modalMap {
  display: flex;
  flex: 1 1;
}

@media (min-width: 768px) {
  .modal-content {
    min-height: auto!important;
  }
  #modalMap {
    height: 500px;
  }
}

.reset-bounds-button {
  position: absolute;
  bottom: 0;
  left: 0;
}

.facet-header-item {
  word-break: break-word;
}
