

























































@import '../styles/bootstrap_theme';

.material-icons {
  font-size: 1em;
}
