

















































































































































































































































@import '../styles/bootstrap_theme';
@import '~leaflet/dist/leaflet.css';
@import '~leaflet-easybutton/src/easy-button.css';

@keyframes blink {
  from {color: #009374}
  to {color: #00c69c}
}

.easy-button-button {
  padding: 0;
  &:hover {
    background-color: #ccc;
  }
  .state-active{
    color: #009374;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-direction: alternate;
  }
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: unset !important;
}
