
















































































































@import '../styles/bootstrap_theme';

.close-facet {
  cursor: pointer;
}
