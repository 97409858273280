




























































































































@import '../styles/bootstrap_theme';

.pagination > li > button
{
  background-color: #fff !important;
  color: #5c636a !important;
}

.pagination > li > button:focus,
.pagination > li > button:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
  color: #5c636a !important;
  background-color: #eee !important;
  border-color: #ddd !important;
}

.pagination > .active > button
{
  color: #fff !important;
  background-color: #5c636a !important;
  border: solid 1px #5c636a !important;
}

.pagination > .active > button:hover
{
  background-color: #5c636a !important;
  border: solid 1px #5c636a !important;
  color: #eee !important;
}
