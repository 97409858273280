























































































@import '../../node_modules/bootstrap/scss/bootstrap.scss';
.language-selector {
  width: 150px;
}
