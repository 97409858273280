












































































































































@import '../styles/bootstrap_theme';
@import '~leaflet/dist/leaflet.css';

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: unset !important;
}
