



















































































// Normalizes default css rules. See: https://github.com/necolas/normalize.css
@import './styles/utils/normalize.css';

* {
  box-sizing: border-box;
}

.site-wrapper {
  //border: 1px solid #83b4c2;
  // margin: auto;
  //box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  .content-wrapper {
    position: relative;
    max-width: 984px;
    margin: auto
  }
}
